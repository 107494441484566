import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="about-page">
      <article className="post our-story">
        {/* <h1>Catering</h1> */}

        <AutoMenu
          tagsToShow={["catering"]}
          ></AutoMenu>

        <p>Call +16267976459 for Questions.</p>
      </article>


    </div>
  )
}
