
import * as Components from "./Domenicos"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "domenicos"
}

