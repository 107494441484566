
import React, { Component } from 'react';
import './about.scss'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        <h1>Our Story</h1>

        <p>

        We are a 3rd generation family restaurant that makes pizza the way you want. Every dinner is made with Mama Rosa's original recipes. We are known for our pizza's and our mouthwatering Antipasto Salad with our homemade Italian dressing. Domenico's is one of Pasadena's oldest restaurants

         </p>

        <p>
          <img
            src="https://afag.imgix.net/domenicos-italian-restaurant/old-family-photo.jpg?auto=format"
           alt="old domenicos family"/>

        </p>

        <p>
          <img src="https://afag.imgix.net/domenicos-italian-restaurant/la-best-award.jpg?auto=format"
           alt="award for LA's best pizza"/>
        </p>
      </article>


    </div>
  )
}
